.time-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  button {
    margin-left:6px;
    &:focus {
      outline: none;
    }
  }
}
.hidePreviousMonthButton {
  .p-datepicker-prev {
    display: none !important;
  }
}

.time-button {
  margin-bottom: 10px;

  width:100%;
  height:100%;
  //min-width:98px;
  &:focus {
    outline: none;
  }
  &:hover:not(.active):not(.disabled) {
    color:var(--accent-color);

  }
}
@media screen and (max-width: 433px){
  .time-button {
      // min-width:66px;
  }
}
.p-calendar .p-datepicker {
  width: initial !important;
  max-width: calc(100vw - 60px);
  min-width:300px;
  .p-highlight{
    border-radius: 15%;
  }
  &.p-datepicker-multiple-month .p-datepicker-group {
    border-right: 0;
  }

  .p-datepicker-header .p-datepicker-prev, .p-datepicker-header .p-datepicker-next {
    border-radius: 15%;
  }

  table {
    td {
      .p-disabled, .p-component:disabled {
        opacity: 0.3;
      }
      > span {
        border-radius: 15%;
        font-weight: 600;
      }
      &.p-datepicker-other-month {
        opacity: 0;
      }

      &.p-datepicker-today > span {
        border-radius: 15%;
        background-color: #ffffff;
        border: 2px #e9ecef solid;
        &.p-highlight {
          border-radius: 15%;
        }
      }

      span:not(.p-highlight):not(.p-disabled) {
        background: #e9ecef;

        &:hover {
          color: var(--accent-color);
        }
      }
    }
  }
}
th {
  text-align: left;
}

.p-menuitem-link:focus, .p-datepicker table td > span:focus  {
  outline: none !important;
  box-shadow: none !important;
}
.p-steps .p-steps-item.p-highlight .p-steps-number, .p-datepicker-calendar .p-highlight, .p-datepicker table td.p-datepicker-today > span.p-highlight {
  background: var(--accent-color-dimmed) !important;
  border: 2px var(--accent-color) solid;
  color: black !important;
}

.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #131215;
  border: 1px solid;
}

@media screen and (max-width: 769px) {
  .p-datepicker table td, .p-datepicker table th {
    padding: 1px;
  }
}
.react-multiple-carousel__arrow {
  z-index: 10 !important;
}
@media screen and (max-width: 550px) {
  .react-multiple-carousel__arrow {
    display: none;
  }
}
.p-dialog-title {
  text-align: center;
}
.animated-button-small {
  height: 80px;
  margin-top:0px;
  .icon {
    display: none;
  }
}
@media (max-width: 770px) {
  .animated-button-small{
    height: 60px;
  }
  }
.animated-button-large {
  height: 120px;
  margin-top:15vh;
  @media screen and (max-width: 561px) {
    .icon {
      display:none !important;
    }
  }
  .icon {
    display: block;
  }
}
.animated-button {
  transition: all 0.35s;
}