.mobile-full-cart {
  @apply w-full h-full z-50 bg-white border-t-2 border-solid border-primary fixed left-0;
  display: none;
  &.opened {
    display: block;
    bottom: 0 !important;
    .cart-content {
      max-height: calc(100vh);
      overflow-y: auto;
    }
  }
}
.bottom-cart-shadow {
  box-shadow: 0px 0px 3em 2em #fff;
}
.inactive-go-next-button {
  @apply text-center text-grey-600 py-2  bg-grey cursor-not-allowed hover:bg-grey border-2 border-grey rounded;
}