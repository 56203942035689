@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.p-card {
  margin: 2rem;
}

@font-face {
  font-family: 'primeicons';
  font-display: auto;
  src: url('https://unpkg.com/primeicons@4.1.0/fonts/primeicons.eot');
  src: url('https://unpkg.com/primeicons@4.1.0/fonts/primeicons.eot?#iefix') format('embedded-opentype'), url('https://unpkg.com/primeicons@4.1.0/fonts/primeicons.ttf') format('truetype'), url('https://unpkg.com/primeicons@4.1.0/fonts/primeicons.woff') format('woff'), url('https://unpkg.com/primeicons@4.1.0/fonts/primeicons.svg?#primeicons') format('svg');
  font-weight: normal;
  font-style: normal;
}
.p-button:enabled:hover {
  background: var(--accent-color-dimmed);
  border-color: var(--accent-color);
  color: #131215;
}
@media (max-width: 579px) {
  .p-confirm-popup-accept, .p-confirm-popup-reject {
    width:100% !important;
  }
}

.qlsize-large {
  font-size: 1.3rem;
}

.react-simple-image-viewer__modal {
  z-index:1000 !important;
}
h1 {
  font-size: 40px;
  font-weight: 800;
}
.items-strech {
  align-items: stretch;
}