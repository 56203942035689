@charset "UTF-8";
:root {
    --surface-a:#ffffff;
    --surface-b:#f8f9fa;
    --surface-c:#e9ecef;
    --surface-d:#dee2e6;
    --surface-e:#ffffff;
    --surface-f:#ffffff;
    --text-color:#495057;
    --text-color-secondary:#6c757d;
    --primary-color:var(--accent-color);
    --primary-color-text:#ffffff;
    --font-family:-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

* {
    box-sizing: border-box;
}

.p-component {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: normal;
}

.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    transition-duration: 0.2s;
}

.p-disabled, .p-component:disabled {
    opacity: 0.6;
}

.p-error {
    color: #f44336;
}

.p-text-secondary {
    color: #6c757d;
}

.pi {
    font-size: 1rem;
}

.p-link {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border-radius: 3px;
}
.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-datepicker {
    padding: 0.5rem;
    background: #ffffff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 3px;
}
.p-datepicker:not(.p-datepicker-inline) {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #ffffff;
}
.p-datepicker .p-datepicker-header {
    padding: 0.5rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #dee2e6;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
    border-color: var(--primary-color);
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem;
}
.p-datepicker table {
    font-size: 1rem;
    margin: 0.5rem 0;
}
.p-datepicker table th {
    padding: 0.5rem;
}
.p-datepicker table th > span {
    width: 2.5rem;
    height: 2.5rem;
}
.p-datepicker table td {
    padding: 0.3rem;
}
.p-datepicker table td > span {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: box-shadow 0.2s;
    border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
    color: #495057;
    background: #E3F2FD;
}
.p-datepicker table td > span:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-datepicker table td.p-datepicker-today > span {
    background: #ced4da;
    color: #495057;
    border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #495057;
    background: #E3F2FD;
}
.p-datepicker .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
}
.p-datepicker .p-timepicker {
    border-top: 1px solid #dee2e6;
    padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
}
.p-datepicker .p-timepicker button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-datepicker .p-timepicker button:last-child {
    margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
    font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
    padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
}
.p-datepicker .p-monthpicker {
    margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.5rem;
    transition: box-shadow 0.2s;
    border-radius: 3px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #495057;
    background: #E3F2FD;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-right: 1px solid #dee2e6;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
    border-right: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #e9ecef;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
    background: #e9ecef;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-datepicker-mask.p-component-overlay {
    background: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 769px) {
    .p-datepicker table th, .p-datepicker table td {
        padding: 0;
    }
}

.p-checkbox {
    width: 20px;
    height: 20px;
}
.p-checkbox .p-checkbox-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #495057;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--primary-color);
    background: var(--primary-color);
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: var(--primary-color);
    background: var(--primary-color);
    color: #ffffff;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: var(--primary-color);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
    border-color: var(--primary-color);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--primary-color);
    background: var(--primary-color);
    color: #ffffff;
}
.p-checkbox.p-invalid > .p-checkbox-box {
    border-color: #f44336;
}

.p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #f8f9fa;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: var(--primary-color);
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #f8f9fa;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: var(--primary-color);
}

.p-inputgroup-addon {
    background: #e9ecef;
    color: #6c757d;
    border-top: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    padding: 0.5rem 0.5rem;
    min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
    border-right: 1px solid #ced4da;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-inputwrapper > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
    z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
    z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-fluid .p-inputgroup .p-button {
    width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.357rem;
}

.p-inputnumber.p-invalid.p-component > .p-inputtext {
    border-color: #f44336;
}

.p-inputswitch {
    width: 3rem;
    height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
    background: #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
    background: #ffffff;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.25rem;
    margin-top: -0.625rem;
    border-radius: 50%;
    transition-duration: 0.2s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #b6bfc8;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--primary-color);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #ffffff;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #0d89ec;
}
.p-inputswitch.p-invalid {
    border-color: #f44336;
}

.p-inputtext {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.5rem 0.5rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 3px;
}
.p-inputtext:enabled:hover {
    border-color: var(--primary-color);
}
.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
    border-color: var(--primary-color);
}
.p-inputtext.p-invalid.p-component {
    border-color: #f44336;
}
.p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
}
.p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 0.625rem 0.625rem;
}

.p-float-label > label {
    left: 0.5rem;
    color: #6c757d;
    transition-duration: 0.2s;
}

.p-input-icon-left > i:first-of-type {
    left: 0.5rem;
    color: #6c757d;
}

.p-input-icon-left > .p-inputtext {
    padding-left: 2rem;
}

.p-input-icon-left.p-float-label > label {
    left: 2rem;
}

.p-input-icon-right > i:last-of-type {
    right: 0.5rem;
    color: #6c757d;
}

.p-input-icon-right > .p-inputtext {
    padding-right: 2rem;
}

::-webkit-input-placeholder {
    color: #6c757d;
}

:-moz-placeholder {
    color: #6c757d;
}

::-moz-placeholder {
    color: #6c757d;
}

:-ms-input-placeholder {
    color: #6c757d;
}

.p-input-filled .p-inputtext {
    background-color: #f8f9fa;
}
.p-input-filled .p-inputtext:enabled:hover {
    background-color: #f8f9fa;
}
.p-input-filled .p-inputtext:enabled:focus {
    background-color: #ffffff;
}

.p-inputtext-sm .p-inputtext {
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
}

.p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: 0.625rem 0.625rem;
}

.p-listbox {
    background: #ffffff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 3px;
}
.p-listbox .p-listbox-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #495057;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.5rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
    right: 0.5rem;
    color: #6c757d;
}
.p-listbox .p-listbox-list {
    padding: 0.5rem 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #495057;
    transition: box-shadow 0.2s;
    border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #495057;
    background: #E3F2FD;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-listbox .p-listbox-list .p-listbox-empty-message {
    padding: 0.5rem 1rem;
    color: #495057;
    background: transparent;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #495057;
    background: #e9ecef;
}
.p-listbox.p-invalid {
    border-color: #f44336;
}

.p-multiselect {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}
.p-multiselect:not(.p-disabled):hover {
    border-color: var(--primary-color);
}
.p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
    border-color: var(--primary-color);
}
.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.5rem;
}
.p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
    color: #6c757d;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #E3F2FD;
    color: #495057;
    border-radius: 3px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: #6c757d;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.p-multiselect .p-multiselect-clear-icon {
    color: #6c757d;
    right: 2.357rem;
}
.p-multiselect.p-invalid.p-component {
    border-color: #f44336;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.25rem 0.5rem;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.5rem;
}

.p-multiselect-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.p-multiselect-panel .p-multiselect-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #495057;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    right: 0.5rem;
    color: #6c757d;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-left: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-multiselect-panel .p-multiselect-items {
    padding: 0.5rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #495057;
    background: #E3F2FD;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #495057;
    background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #a6d5fa;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: 0.5rem 1rem;
    color: #495057;
    background: transparent;
}

.p-input-filled .p-multiselect {
    background: #f8f9fa;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #f8f9fa;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: #ffffff;
}

.p-password.p-invalid.p-component > .p-inputtext {
    border-color: #f44336;
}

.p-password-panel {
    padding: 1rem;
    background: #ffffff;
    color: #495057;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}
.p-password-panel .p-password-meter {
    margin-bottom: 0.5rem;
    background: #dee2e6;
}
.p-password-panel .p-password-meter .p-password-strength.weak {
    background: #D32F2F;
}
.p-password-panel .p-password-meter .p-password-strength.medium {
    background: #FBC02D;
}
.p-password-panel .p-password-meter .p-password-strength.strong {
    background: #689F38;
}

.p-radiobutton {
    width: 20px;
    height: 20px;
}
.p-radiobutton .p-radiobutton-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #495057;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: var(--primary-color);
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
    border-color: var(--primary-color);
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 12px;
    height: 12px;
    transition-duration: 0.2s;
    background-color: #ffffff;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: var(--primary-color);
    background: var(--primary-color);
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: var(--primary-color);
    background: var(--primary-color);
    color: #ffffff;
}
.p-radiobutton.p-invalid > .p-radiobutton-box {
    border-color: #f44336;
}
.p-radiobutton:focus {
    outline: 0 none;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
    background-color: #f8f9fa;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #f8f9fa;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: var(--primary-color);
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: var(--primary-color);
}

.p-rating .p-rating-icon {
    color: #495057;
    margin-left: 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
    color: #e74c3c;
}
.p-rating .p-rating-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-rating .p-rating-icon:first-child {
    margin-left: 0;
}
.p-rating .p-rating-icon.pi-star {
    color: var(--primary-color);
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
    color: var(--primary-color);
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
    color: #c0392b;
}

.p-selectbutton .p-button {
    background: #ffffff;
    border: 1px solid #ced4da;
    color: #495057;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
    color: #6c757d;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
    border-color: #ced4da;
    color: #495057;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #6c757d;
}
.p-selectbutton .p-button.p-highlight {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: #ffffff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover {
    background: #0d89ec;
    border-color: #0d89ec;
    color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #ffffff;
}
.p-selectbutton.p-invalid > .p-button {
    border-color: #f44336;
}

.p-slider {
    background: #dee2e6;
    border: 0 none;
    border-radius: 3px;
}
.p-slider.p-slider-horizontal {
    height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -0.5715rem;
    margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
    width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
    margin-left: -0.5715rem;
    margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: #ffffff;
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-slider .p-slider-handle:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-slider .p-slider-range {
    background: var(--primary-color);
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: var(--primary-color);
    border-color: var(--primary-color);
}

.p-togglebutton.p-button {
    background: #ffffff;
    border: 1px solid #ced4da;
    color: #495057;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
    color: #6c757d;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
    border-color: #ced4da;
    color: #495057;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #6c757d;
}
.p-togglebutton.p-button.p-highlight {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: #ffffff;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover {
    background: #0d89ec;
    border-color: #0d89ec;
    color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: #ffffff;
}
.p-togglebutton.p-button.p-invalid > .p-button {
    border-color: #f44336;
}

.p-button {
    color: #ffffff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}
.p-button:enabled:hover {
    background: #0d89ec;
    color: #ffffff;
    border-color: #0d89ec;
}
.p-button:enabled:active {
    background: var(--primary-color);
    color: #ffffff;
    border-color: var(--primary-color);
}
.p-button.p-button-outlined {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
    background: rgba(33, 150, 243, 0.04);
    color: var(--primary-color);
    border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
    background: rgba(33, 150, 243, 0.16);
    color: var(--primary-color);
    border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
    color: #6c757d;
    border-color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
    background: #e9ecef;
    color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
    background: #dee2e6;
    color: #6c757d;
}
.p-button.p-button-text {
    background-color: transparent;
    color: var(--primary-color);
    border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
    background: rgba(33, 150, 243, 0.04);
    color: var(--primary-color);
    border-color: transparent;
}
.p-button.p-button-text:enabled:active {
    background: rgba(33, 150, 243, 0.16);
    color: var(--primary-color);
    border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
    color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:hover {
    background: #e9ecef;
    color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:active {
    background: #dee2e6;
    color: #6c757d;
}
.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-button .p-button-icon-left {
    margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
    margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
    margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
    margin-bottom: 0.5rem;
}
.p-button .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: var(--primary-color);
    background-color: #ffffff;
}
.p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
    border-radius: 2rem;
}
.p-button.p-button-icon-only {
    width: 2.357rem;
    padding: 0.5rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
    margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.357rem;
}
.p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
}
.p-button.p-button-sm .p-button-icon {
    font-size: 0.875rem;
}
.p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.625rem 1.25rem;
}
.p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem;
}

.p-fluid .p-button {
    width: 100%;
}
.p-fluid .p-button-icon-only {
    width: 2.357rem;
}
.p-fluid .p-buttonset {
    display: flex;
}
.p-fluid .p-buttonset .p-button {
    flex: 1;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
    color: #ffffff;
    background: #607D8B;
    border: 1px solid #607D8B;
}
.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
    background: #56717d;
    color: #ffffff;
    border-color: #56717d;
}
.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #beccd2;
}
.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
    background: #4d646f;
    color: #ffffff;
    border-color: #4d646f;
}
.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    background-color: transparent;
    color: #607D8B;
    border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
    background: rgba(96, 125, 139, 0.04);
    color: #607D8B;
    border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
    background: rgba(96, 125, 139, 0.16);
    color: #607D8B;
    border: 1px solid;
}
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
    background-color: transparent;
    color: #607D8B;
    border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
    background: rgba(96, 125, 139, 0.04);
    border-color: transparent;
    color: #607D8B;
}
.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
    background: rgba(96, 125, 139, 0.16);
    border-color: transparent;
    color: #607D8B;
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
    color: #ffffff;
    background: #0288D1;
    border: 1px solid #0288D1;
}
.p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
    background: #027abc;
    color: #ffffff;
    border-color: #027abc;
}
.p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #89d4fe;
}
.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
    background: #026da7;
    color: #ffffff;
    border-color: #026da7;
}
.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
    background-color: transparent;
    color: #0288D1;
    border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
    background: rgba(2, 136, 209, 0.04);
    color: #0288D1;
    border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    color: #0288D1;
    border: 1px solid;
}
.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
    background-color: transparent;
    color: #0288D1;
    border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
    background: rgba(2, 136, 209, 0.04);
    border-color: transparent;
    color: #0288D1;
}
.p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    border-color: transparent;
    color: #0288D1;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
    color: #ffffff;
    background: #689F38;
    border: 1px solid #689F38;
}
.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
    background: #5e8f32;
    color: #ffffff;
    border-color: #5e8f32;
}
.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #c2e0a8;
}
.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
    background: #537f2d;
    color: #ffffff;
    border-color: #537f2d;
}
.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
    background-color: transparent;
    color: #689F38;
    border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
    background: rgba(104, 159, 56, 0.04);
    color: #689F38;
    border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
    background: rgba(104, 159, 56, 0.16);
    color: #689F38;
    border: 1px solid;
}
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
    background-color: transparent;
    color: #689F38;
    border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
    background: rgba(104, 159, 56, 0.04);
    border-color: transparent;
    color: #689F38;
}
.p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
    background: rgba(104, 159, 56, 0.16);
    border-color: transparent;
    color: #689F38;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
    color: #212529;
    background: #FBC02D;
    border: 1px solid #FBC02D;
}
.p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
    background: #fab710;
    color: #212529;
    border-color: #fab710;
}
.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #fde6ab;
}
.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
    background: #e8a704;
    color: #212529;
    border-color: #e8a704;
}
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    background-color: transparent;
    color: #FBC02D;
    border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
    background: rgba(251, 192, 45, 0.04);
    color: #FBC02D;
    border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
    background: rgba(251, 192, 45, 0.16);
    color: #FBC02D;
    border: 1px solid;
}
.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
    background-color: transparent;
    color: #FBC02D;
    border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
    background: rgba(251, 192, 45, 0.04);
    border-color: transparent;
    color: #FBC02D;
}
.p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
    background: rgba(251, 192, 45, 0.16);
    border-color: transparent;
    color: #FBC02D;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
    color: #ffffff;
    background: #9C27B0;
    border: 1px solid #9C27B0;
}
.p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
    background: #8c239e;
    color: #ffffff;
    border-color: #8c239e;
}
.p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #df9eea;
}
.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
    background: #7d1f8d;
    color: #ffffff;
    border-color: #7d1f8d;
}
.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
    background-color: transparent;
    color: #9C27B0;
    border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
    background: rgba(156, 39, 176, 0.04);
    color: #9C27B0;
    border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
    background: rgba(156, 39, 176, 0.16);
    color: #9C27B0;
    border: 1px solid;
}
.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
    background-color: transparent;
    color: #9C27B0;
    border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
    background: rgba(156, 39, 176, 0.04);
    border-color: transparent;
    color: #9C27B0;
}
.p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
    background: rgba(156, 39, 176, 0.16);
    border-color: transparent;
    color: #9C27B0;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
    color: #ffffff;
    background: #D32F2F;
    border: 1px solid #D32F2F;
}
.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
    background: #c02929;
    color: #ffffff;
    border-color: #c02929;
}
.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #edacac;
}
.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
    background: #aa2424;
    color: #ffffff;
    border-color: #aa2424;
}
.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: #D32F2F;
    border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
    background: rgba(211, 47, 47, 0.04);
    color: #D32F2F;
    border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
    background: rgba(211, 47, 47, 0.16);
    color: #D32F2F;
    border: 1px solid;
}
.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
    background-color: transparent;
    color: #D32F2F;
    border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
    background: rgba(211, 47, 47, 0.04);
    border-color: transparent;
    color: #D32F2F;
}
.p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
    background: rgba(211, 47, 47, 0.16);
    border-color: transparent;
    color: #D32F2F;
}

.p-button.p-button-link {
    color: var(--primary-color);
    background: transparent;
    border: transparent;
}
.p-button.p-button-link:enabled:hover {
    background: transparent;
    color: var(--primary-color);
    border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
    text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
    background: transparent;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
    border-color: transparent;
}
.p-button.p-button-link:enabled:active {
    background: transparent;
    color: var(--primary-color);
    border-color: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-carousel .p-carousel-indicators {
    padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #e9ecef;
    width: 2rem;
    height: 0.5rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: #dee2e6;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: #E3F2FD;
    color: #495057;
}

.p-datatable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
}
.p-datatable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
}
.p-datatable .p-datatable-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}
.p-datatable .p-datatable-footer {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}
.p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #6c757d;
    margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #495057;
    background: #E3F2FD;
    margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #6c757d;
}
.p-datatable .p-sortable-column.p-highlight {
    background: #f8f9fa;
    color: var(--primary-color);
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--primary-color);
}
.p-datatable .p-sortable-column.p-highlight:hover {
    background: #e9ecef;
    color: var(--primary-color);
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: var(--primary-color);
}
.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem #a6d5fa;
    outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
    background: #ffffff;
    color: #495057;
    transition: box-shadow 0.2s;
    outline-color: #a6d5fa;
}
.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #E3F2FD;
    color: #495057;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 #E3F2FD;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 #E3F2FD;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: #e9ecef;
    color: #495057;
}
.p-datatable .p-column-resizer-helper {
    background: var(--primary-color);
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
    background: #f8f9fa;
}
.p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: #fcfcfc;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background: #E3F2FD;
    color: #495057;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
    color: #495057;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
    color: #495057;
}
.p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
    padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
}
.p-dataview .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
}
.p-dataview .p-dataview-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}
.p-dataview .p-dataview-content {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: solid #e9ecef;
    border-width: 0 0 1px 0;
}
.p-dataview .p-dataview-footer {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
.p-dataview .p-dataview-loading-icon {
    font-size: 2rem;
}

.p-datascroller .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
}
.p-datascroller .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
}
.p-datascroller .p-datascroller-header {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}
.p-datascroller .p-datascroller-content {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    padding: 0;
}
.p-datascroller.p-datascroller-inline .p-datascroller-list > li {
    border: solid #e9ecef;
    border-width: 0 0 1px 0;
}
.p-datascroller .p-datascroller-footer {
    background: #f8f9fa;
    color: #495057;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.fc .fc-view-container th {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    color: #495057;
}
.fc .fc-view-container td.fc-widget-content {
    border: 1px solid #dee2e6;
    color: #495057;
}
.fc .fc-view-container td.fc-head-container {
    border: 1px solid #dee2e6;
}
.fc .fc-view-container .fc-view {
    background: #ffffff;
}
.fc .fc-view-container .fc-row {
    border-right: 1px solid #dee2e6;
}
.fc .fc-view-container .fc-event {
    background: #0d89ec;
    border: 1px solid #0d89ec;
    color: #ffffff;
}
.fc .fc-view-container .fc-divider {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
}

.p-card {
    background: #ffffff;
    color: #495057;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}
.p-card .p-card-body {
    padding: 1rem;
}
.p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: #6c757d;
}
.p-card .p-card-content {
    padding: 1rem 0;
}
.p-card .p-card-footer {
    padding: 1rem 0 0 0;
}

.p-fieldset {
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-radius: 3px;
}
.p-fieldset .p-fieldset-legend {
    padding: 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    background: #f8f9fa;
    font-weight: 600;
    border-radius: 3px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 1rem;
    color: #495057;
    border-radius: 3px;
    transition: box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: #e9ecef;
    border-color: #dee2e6;
    color: #495057;
}
.p-fieldset .p-fieldset-content {
    padding: 1rem;
}

.p-divider .p-divider-content {
    background-color: #ffffff;
}
.p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem;
}
.p-divider.p-divider-horizontal:before {
    border-top: 1px #dee2e6;
}
.p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0;
}
.p-divider.p-divider-vertical:before {
    border-left: 1px #dee2e6;
}
.p-divider.p-divider-vertical .p-divider-content {
    padding: 0.5rem 0;
}

.p-panel .p-panel-header {
    border: 1px solid #dee2e6;
    padding: 1rem;
    background: #f8f9fa;
    color: #495057;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.p-panel .p-panel-header .p-panel-title {
    font-weight: 600;
}
.p-panel .p-panel-header .p-panel-header-icon {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-panel.p-panel-toggleable .p-panel-header {
    padding: 0.5rem 1rem;
}
.p-panel .p-panel-content {
    padding: 1rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 0 none;
}
.p-panel .p-panel-footer {
    padding: 0.5rem 1rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-top: 0 none;
}

.p-splitter {
    border: 1px solid #dee2e6;
    background: #ffffff;
    border-radius: 3px;
    color: #495057;
}
.p-splitter .p-splitter-gutter {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    background: #f8f9fa;
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: #dee2e6;
}
.p-splitter .p-splitter-gutter-resizing {
    background: #dee2e6;
}

.p-scrollpanel .p-scrollpanel-bar {
    background: #f8f9fa;
    border: 0 none;
}

.p-tabview .p-tabview-nav {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-nav li {
    margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #dee2e6;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #dee2e6 transparent;
    background: #ffffff;
    color: #6c757d;
    padding: 1rem;
    font-weight: 600;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: #ffffff;
    border-color: #6c757d;
    color: #6c757d;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: var(--primary-color);
    color: var(--primary-color);
}
.p-tabview .p-tabview-panels {
    background: #ffffff;
    padding: 1rem;
    border: 0 none;
    color: #495057;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-toolbar {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    padding: 1rem;
    border-radius: 3px;
}
.p-toolbar .p-toolbar-separator {
    margin: 0 0.5rem;
}

.p-confirm-popup {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.p-confirm-popup .p-confirm-popup-content {
    padding: 1rem;
}
.p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: 0 1rem 1rem 1rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0;
}
.p-confirm-popup:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
}
.p-confirm-popup:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
}
.p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: #ffffff;
}
.p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: #ffffff;
}
.p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem;
}

.p-dialog {
    border-radius: 3px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border: 0 none;
}
.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 1rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
}
.p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 0 1.5rem 2rem 1.5rem;
}
.p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 0 1.5rem 1.5rem 1.5rem;
    text-align: right;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
}

.p-overlaypanel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.p-overlaypanel .p-overlaypanel-content {
    padding: 1rem;
}
.p-overlaypanel .p-overlaypanel-close {
    background: var(--primary-color);
    color: #ffffff;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #0d89ec;
    color: #ffffff;
}
.p-overlaypanel:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
}
.p-overlaypanel:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #ffffff;
}

.p-sidebar {
    background: #ffffff;
    color: #495057;
    padding: 1rem;
    border: 0 none;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.p-sidebar .p-sidebar-close,
.p-sidebar .p-sidebar-icon {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-sidebar .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-icon:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
}
.p-sidebar .p-sidebar-close:focus,
.p-sidebar .p-sidebar-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-sidebar-mask.p-component-overlay {
    background: rgba(0, 0, 0, 0.4);
}

.p-tooltip .p-tooltip-text {
    background: #495057;
    color: #ffffff;
    padding: 0.5rem 0.5rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #495057;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #495057;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #495057;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #495057;
}

.p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 3px;
    background: #ffffff;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #495057;
    border: 1px solid #e9ecef;
    background: #ffffff;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 50%;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: #6c757d;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #E3F2FD;
    color: #495057;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 600;
    color: #495057;
}
.p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid #dee2e6;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: solid #dee2e6;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #dee2e6 transparent;
    background: #ffffff;
    color: #6c757d;
    padding: 1rem;
    font-weight: 600;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: #ffffff;
    border-color: #6c757d;
    color: #6c757d;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #ffffff;
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.p-inline-message {
    padding: 0.5rem 0.5rem;
    margin: 0;
    border-radius: 3px;
}
.p-inline-message.p-inline-message-info {
    background: #B3E5FC;
    border: solid #0891cf;
    border-width: 0px;
    color: #044868;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #044868;
}
.p-inline-message.p-inline-message-success {
    background: #C8E6C9;
    border: solid #439446;
    border-width: 0px;
    color: #224a23;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #224a23;
}
.p-inline-message.p-inline-message-warn {
    background: #FFECB3;
    border: solid #d9a300;
    border-width: 0px;
    color: #6d5100;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #6d5100;
}
.p-inline-message.p-inline-message-error {
    background: #FFCDD2;
    border: solid #e60017;
    border-width: 0px;
    color: #73000c;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #73000c;
}
.p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
    font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
}

.p-message {
    margin: 1rem 0;
    border-radius: 3px;
}
.p-message .p-message-wrapper {
    padding: 1rem 1.5rem;
}
.p-message .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-message .p-message-close:hover {
    background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}
.p-message.p-message-info {
    background: #B3E5FC;
    border: solid #0891cf;
    border-width: 0 0 0 6px;
    color: #044868;
}
.p-message.p-message-info .p-message-icon {
    color: #044868;
}
.p-message.p-message-info .p-message-close {
    color: #044868;
}
.p-message.p-message-success {
    background: #C8E6C9;
    border: solid #439446;
    border-width: 0 0 0 6px;
    color: #224a23;
}
.p-message.p-message-success .p-message-icon {
    color: #224a23;
}
.p-message.p-message-success .p-message-close {
    color: #224a23;
}
.p-message.p-message-warn {
    background: #FFECB3;
    border: solid #d9a300;
    border-width: 0 0 0 6px;
    color: #6d5100;
}
.p-message.p-message-warn .p-message-icon {
    color: #6d5100;
}
.p-message.p-message-warn .p-message-close {
    color: #6d5100;
}
.p-message.p-message-error {
    background: #FFCDD2;
    border: solid #e60017;
    border-width: 0 0 0 6px;
    color: #73000c;
}
.p-message.p-message-error .p-message-icon {
    color: #73000c;
}
.p-message.p-message-error .p-message-close {
    color: #73000c;
}
.p-message .p-message-text {
    font-size: 1rem;
    font-weight: 500;
}
.p-message .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
}

.p-chip {
    background-color: #dee2e6;
    color: #495057;
    border-radius: 16px;
    padding: 0 0.5rem;
}
.p-chip .p-chip-text {
    line-height: 1.5;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.p-chip .p-chip-icon {
    margin-right: 0.5rem;
}
.p-chip img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
}
.p-chip .p-chip-remove-icon {
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-left: 0.5rem;
}
.p-chip .p-chip-remove-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-scrolltop {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-scrolltop.p-link {
    background: rgba(0, 0, 0, 0.7);
}
.p-scrolltop.p-link:hover {
    background: rgba(0, 0, 0, 0.8);
}
.p-scrolltop .p-scrolltop-icon {
    font-size: 1.5rem;
    color: #f8f9fa;
}

.p-skeleton {
    background-color: #e9ecef;
    border-radius: 3px;
}
.p-skeleton:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-inplace .p-inplace-display {
    padding: 0.5rem 0.5rem;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: #e9ecef;
    color: #495057;
}
.p-inplace .p-inplace-display:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
}

/* Customizations to the designer theme should be defined here */
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: var(--primary-color);
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: var(--primary-color);
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    border-left: 1px #ddd solid !important;
}