.weapon-info-dialog .p-dialog-header {
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: transparent;
  .p-dialog-header-icon {

    background: rgba(255,255,255, 0.8);
  }
}