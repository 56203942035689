@import "themes/base.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


@import url('https://fonts.googleapis.com/css2?family=Righteous&display=block');
body, html {
  margin: 0;
  min-height: 100%;
  --tw-bg-opacity: 1;
  background-color: none;
  font-family: Source Sans Pro, Roboto, Helvetica;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a, button, .p-button, .p-datepicker td > span, .p-link, .p-datepicker-next, .p-datepicker-prev, .p-checkbox {
  @apply cursor-default hover:cursor-pointer select-none;
}
span {
  cursor: inherit;
}
button:focus {
  outline: none;
}
.logo {
  font-family: Righteous;
  font-weight: 400;
  font-size: 20px;
}
* {
  --tw-ring-color: none;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none;
}
@font-face {
  font-family: 'primeicons';
  font-display: auto;
  src: url('~primeicons/fonts/primeicons.eot');
  src: url('~primeicons/fonts/primeicons.eot?#iefix') format('embedded-opentype'), url('~primeicons/fonts/primeicons.ttf') format('truetype'), url('~primeicons/fonts/primeicons.woff') format('woff'), url('~primeicons/fonts/primeicons.svg?#primeicons') format('svg');
  font-weight: normal;
  font-style: normal;
}