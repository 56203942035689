.blockedContent {
  overflow: hidden;
  position: fixed;
  width:100%;
}
@media screen and (min-width: 1024px) {
  .main-container {
    max-height: calc(100vh - 2rem);
  }
}

@media screen and (max-width: 1024px) {
  .main-container {
    max-height: 100vh;
  }
}

.main-container {
  height: calc(100vh - 2rem);
  display: flex;
  flex-direction: column;
  flex-grow: revert-layer;
  }