.dialog-content {
  margin: 1rem;
}

.dialog-content h1 {
  font-weight: 700;
  font-size: 2rem;
}

.dialog-content h2 {
  font-weight: 700;
  font-size: 1.5rem;
}

.dialog-wrapper {
  display: inline;
}
