.language-wrapper {
    @apply z-50 rounded;
    position: absolute;
    right: 70px;
    padding:0.5rem;
    cursor: pointer;
    &:hover {
      background-color:rgb(216 216 216);
  
    }
  
    .flag {
      display: inline;
      border-radius: 50%;
      width: 24px;
      height:24px;
      object-fit: cover;

    }
  }
 
  .selector-element {
    display: flex;
    padding: 0.75rem 1.25rem;
    margin-left:auto;
    line-height: 20px;
    &:hover {
      @apply bg-secondary-dimmed;
    }

    cursor: pointer;
    text-align: center;
   
    .selector-lang {
      border-radius: 50%;
      object-fit: cover;
      width:20px;
      height:20px;
      margin-right: 0.5rem;
  
    }
  }
  .language-dialog {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);

  }

  @media(min-width: 1024px) {
    .language-wrapper {
      right:80px;
    }
  }