.purchase-navigation {
  .disabled {
    .p-menuitem-link {
      .p-steps-number, .p-steps-title {
        color: #ddd !important;
      }
    }
  }
  .p-steps-item {
    &:first-child {
      &:before {
        left: 50%;
      }
    }

    &:last-child {
      &:before {
        width:50%;
      }
    }
    .p-menuitem-link {
      .p-steps-number {
        @apply mt-2;
        min-width: 2.125rem;
        height: 2.125rem;
        line-height: 2.125rem;
        @apply border-grey-300;
      }
      .p-steps-title {
        @apply mt-4;
      }
    }
    &.p-highlight {
      .p-steps-number {
        @apply mt-0;
        @apply border-secondary;
        width: 2.625em;
        height: 2.625em;
      }
      .p-steps-title {
        @apply mt-2;
        @apply text-lg;
      }
    }
  }
}

.p-steps {
  position: relative;
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
  }
  .p-steps-item:before {
    content: " ";
    border-top: 1px solid #dee2e6;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
  }
  .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: box-shadow .2s;
    border-radius: 3px;
    background: #fff;
  }
}
.p-component, .p-component * {
  box-sizing: border-box;
}
.p-component {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
}

.p-steps-item {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  .p-menuitem-link {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    text-decoration: none;
  }
}

.p-steps-item.p-steps-current .p-menuitem-link {
  cursor: default;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--accent-color-dimmed)!important;
  border: 2px solid var(--accent-color);
  color: #000!important;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #495057;
  white-space: nowrap;
  display: block;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #495057;
  background: #fff;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  color: #6c757d;
}
.p-steps-number {
  display: flex;
  align-items: center;
  justify-content: center;
}