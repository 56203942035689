.order-type-selectors-group {
    @apply md:mt-10vh mx-auto px-0 sm:px-4 justify-center content-center pb-16 md:pb-0 grid;

    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    width:100%;
    column-gap: 10px;
    gap: 20px;
    align-content: stretch;

    .order-type-selector-wrapper {
        @apply justify-center items-center text-center;
        min-width:140px;
    }
    .order-type-selector-loaded {
        @apply mt-3 shadow-xl border-secondary p-8 pt-4 items-center justify-center cursor-pointer;
      
        aspect-ratio: 1 / 1;
        padding: 5%;
        max-height:270px;
    }
    .order-type-selector {
        transition: all 0.35s;
        @apply shadow-xl border-secondary rounded p-8 pt-4 text-center bg-white items-center justify-center cursor-pointer;
        &:hover {
            @apply drop-shadow-xl bg-grey-50;
        }
        padding: 5%;
        &.selected {
            @apply bg-secondary text-white shadow-2xl;
            .icons {
                .mobile-icon, .desktop-icon {
                    @apply text-contrast;
                }
            }
            .title, .description {
                @apply text-contrast;
            }
            &:hover {
                @apply bg-secondary-darker2;
            }
        }
        .icons {
            @apply w-16 h-16 md:w-20 md:h-20 rounded-lg pt-2 mx-auto;
            margin-top:10%;
            .mobile-icon {
                @apply mx-auto text-secondary md:hidden;
            }

            .desktop-icon {
                @apply mx-auto text-secondary hidden md:block mt-1
            }
        }
        .title {
            @apply text-xs text-grey-600 font-bold md:text-base lg:text-xl;
            margin-top:10%;
            margin-bottom:5%
        }
        .voucher-title {
            @apply text-xs text-grey-600 font-bold md:text-base lg:text-xl;
            margin-top:7%;
        }
        .description {
            @apply text-sm text-grey-400;
        }

    }

    @media (max-width: 848px) {
        .order-type-selector .title {
            margin-top:20%;
            margin-bottom:0%;
        }
        .order-type-selector-wrapper:nth-of-type(even) {
            .order-type-selector {
            margin-left: 0;
            margin-right:auto;
            }
        }

        .order-type-selector-wrapper:nth-of-type(odd){
            .order-type-selector {
                margin-right: 0;
                margin-left:auto;
            }
        }
        .order-type-selector .description {
            @apply hidden;
        }
    }

}
@media (max-width: 848px) {
    .order-type-selectors-group {
        grid-template-columns: 1fr 1fr;
        grid-row: auto auto;
        justify-content: stretch;
    }
    .order-type-selector {
        width:200px !important;
        height:200px;
        &.order-type-selector-longer {
            height:250px;
            max-height:250px;
        }
    }
}
@media (max-width: 469px) {
    .order-type-selector {
        width:160px !important;
        height:160px;

        &.order-type-selector-longer {
            height:250px;
            max-height:250px;
        }
    }
}

  @media (min-width: 849px) {
  
    .order-type-selectors-group {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: stretch;
    }
    .order-type-selectors-group-4-elems {
        grid-template-columns: repeat(2, 2fr);
        justify-content: stretch;
        max-width: 800px;
        margin-top:5vh;
    }
    .order-type-selectors-group-3-elems {
        grid-template-columns: repeat(3, 1fr);
        justify-content: stretch;
    }
    .order-type-selectors-group-2-elems {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: center;
        :nth-child(1) {
            grid-column:2;
        }
        :nth-child(2) {
            grid-column: 3;
        }
    }
    .order-type-selectors-group .order-type-selector {
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        justify-content: stretch;
    }
  }

  .order-type-selector-longer {
    padding-bottom: 10rem!important;
  }