@media (max-width: 1023px) {
  // .package-info-dialog {
  //   .p-dialog-header {
  //     padding: 0;
  //     position: absolute;
  //     right: 1rem;
  //     top: 1rem;
  //     background: transparent;
  //     .p-dialog-header-icon {
  //       //background: rgba(255,255,255, 0.8);
  //       background: rgba(0,0,0, 0.3);
  //       color:#fff;
  //       z-index: 1000;
  //     }
  //   }

  // }
  .p-component-overlay, .p-dialog, .p-dialog-content {
    overflow-x: hidden;
  }

}

@media (max-width: 475px) {
  .p-calendar .p-datepicker table td > span{
    margin-left: 2px; 
    margin-right:2px;
  }
  .p-datepicker table td > span {
    width: 2.21rem;
    height: 2.21rem;
  }
}
@media (max-width: 770px) {
.p-datepicker table td {
  padding: 0.35rem;
}
}
@media (max-width: 470px) {
  .p-datepicker table td {
    padding: 1px;
  }
}