.add-to-cart {
  @apply text-sm font-medium items-center rounded bg-secondary text-white shadow-lg w-full  flex justify-center  mx-auto lg:mx-0 mb-4 max-w-full mt-0 md:mt-8 md:mb-0;
  .text {
    @apply flex-auto p-2 text-base;
  }
}
.add-to-cart-small {
  @apply px-2 my-1 ;
  min-width: 230px;
  max-width: 450px;
}
.add-to-cart-xsmall {
  @apply px-2 my-1 ;
  min-width: 150px;
  max-width: 250px;

}
.add-to-cart-disabled {
  @apply bg-grey-150 border-grey-150 ;
}
.add-to-cart-hover {
  @apply  hover:bg-secondary-lighter;
}
.select-booking {
  @apply items-center p-2  bg-secondary font-bold text-white shadow-lg   flex justify-center rounded hover:bg-secondary-lighter;
}
.update-booking {
  @apply items-center p-2  bg-secondary text-white font-bold shadow-lg flex justify-center rounded hover:bg-secondary-lighter;
}
.add-to-cart-mobile {
 

margin-left:auto;
.add-to-cart {
  @apply mb-0 px-4;
}
}
.more-details-mobile {

}
.more-details-mobile-auto {
  margin-left:auto;
  display:block !important;
}