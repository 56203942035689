.product-card {
    .package-content{
        overflow-y: hidden;
        max-height:0;
        transition-property: all;
        transition-duration: .5s;
    }
       .unfolded {
           max-height: 1000px;
       }
   }
   .p-dialog .p-dialog-content  {
     padding:0;
   }
   .image-mobile {
    max-width: 380px;
    max-height: 200px;
    width: auto;
    height: auto;
}
.image-mobile-blurred {
  height:150px;
  width:initial;
}
   .image-small {
        max-width: 380px;
        max-height: 200px;
   }

   .availability-button {
    @apply items-center p-1  bg-white text-primary cursor-pointer inline-block float-right justify-center  mx-auto lg:mx-0 mb-2 max-w-full mt-2 px-4;
    .text {
      @apply ml-0 flex-auto p-2 pr-0 text-base;
    }
  }
  .availability-button-small {
    @apply px-2 my-1 ;
    min-width: 250px;
    max-width: 450px;
  }
  .availability-button-xsmall {
    @apply px-2 my-1 ;
    min-width: 150px;
    max-width: 250px;
  
  }
  .availability-button-disabled {
    @apply bg-grey-150 border-grey-150 ;
  }
  .availability-button-hover {
    @apply   hover:text-secondary;
  }

  .availability-legend {
    @apply h-3 w-3 inline-block border;
    &.avaialble {
      
      background-color: #5E7782;
      border-color: #5E7782;
    }
    &.occupied {
      @apply border-red bg-red;
    }
  }

  .bg-available {
    background-color: #5E7782;
  }

 

  .availability-table {
    overflow-x: auto;
    width: fit-content;
    max-width: 100%;
    display: block;
  }

  .availability-table tr>th:first-child,tr>td:first-child {
    position: sticky;
    left: 0;
  }
  
  .availability-table tr td {
     background: white;
  }
  @media (min-width: 540px) {
  .availability-popup {
    min-width: 520px;
  }
}
@media (max-width: 540px) {
  .availability-popup {
    width: 100%;
  }
}
.border-0 .p-datepicker {
  border: 0!important;
}