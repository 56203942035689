.product-card {
 .package-content{
     overflow-y: hidden;
     max-height:0;
     transition-property: all;
     transition-duration: .5s;
 }
    .unfolded {
        max-height: 1000px;
    }
}
.p-dialog .p-dialog-content  {
  padding:0;
}
.package-name{
    cursor:pointer;
}
.package-info-dialog .p-dialog-header {
    @apply hidden lg:block;
    border-top-left-radius: 1rem;
    //display:none;
    border-top-right-radius: 1rem;
}