
.book-bundle-box--recommended {
  overflow: hidden;
  width: 120px;
  height: 60px;
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 1;
  & > strong {
    border: 2px var(--accent-color);
    border-style: solid;
  }
  & > strong, &:after {

    color: #ffffff;
    font-weight: 600;
    background: var(--accent-color);
    border-radius:5px;
    width: 100px;
    left: 5px;
    position: absolute;
    box-sizing: border-box;
    height: 30px;
    line-height: 25px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    z-index: 3;
  }
}
del {
  color: rgba(red, 0.5);
  text-decoration: none;
  position: relative;
  &:before {
    content: " ";
    display: block;
    width: 100%;
    border-top: 2px solid rgba(red, 0.8);
    height: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: rotate(-7deg);
  }
}

.p-dialog, .p-dialog-mask.p-component-overlay {
  pointer-events: auto;
}

.p-dialog-visible {
  display: flex;
}
.p-dialog-mask {
  @apply items-baseline	lg:items-center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  transition-property: background-color;
}